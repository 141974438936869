@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
}

.h-screen-nav {
    height: calc(100vh - 64px);
}

.min-h-screen-nav {
    min-height: calc(100vh - 64px);
}

.max-h-screen-nav {
    max-height: calc(100vh - 64px);
}

* {
    outline: none;
}

:focus:not(:focus-visible) {
    outline: none;
}

.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}